import React, { FC } from 'react';
import { Button as MuiButton } from 'gatsby-theme-material-ui';
import { ButtonProps } from '@material-ui/core';
import { css } from 'styled-components';
import theme from 'src/shared/theme';
import Spinner from 'components/shared/spinner/spinner';

const Button: FC<ButtonProps & { to?: string; loading?: boolean }> = ({
  loading,
  children,
  ...rest
}) => (
  <MuiButton {...rest} disableElevation>
    {loading ? (
      <div
        css={css`
          width: 1em;
          height: 1em;
          position: relative;
          margin-right: ${theme.spacing(2)};
        `}
      >
        <Spinner
          css={css`
            background-color: transparent;
          `}
        />
      </div>
    ) : null}
    {children}
  </MuiButton>
);

export default Button;
