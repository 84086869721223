import { useMediaQuery } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import { XS } from 'src/shared/media-query';
import theme from 'src/shared/theme';
import styled from 'styled-components';

interface Props {
  className?: string;
  white?: boolean;
}

const Wrapper = styled(Link)`
  position: relative;

  @media ${XS}{
    flex: 0 0 470px;
  }
`;

const Img = styled.img`
  height: 100%;
  max-width: 100%;
`;

const Brand: FC<Props> = ({ white, className, ...rest }) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up(`sm`));
  let src = ``;

  if (isDesktop) {
    src = white ? `/assets/brand-white.svg` : `/assets/brand.svg`;
  } else {
    src = white ? `/assets/brand-white-simple.svg` : `/assets/brand-simple.svg`;
  }

  return (
    <Wrapper className={className} to="/" {...rest}>
      <Img src={src} alt="logo" />
    </Wrapper>
  );
};

export default Brand;
