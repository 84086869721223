import React, { FC } from 'react';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { motion } from 'framer-motion';
import { css } from 'styled-components';

interface Props {
  className?: string;
}

const Spinner: FC<Props> = ({ className }) => (
  <div
    css={css`
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #8989897d;
      z-index: 1;
    `}
    className={className}
  >
    <motion.div
      animate={{
        rotate: 360,
        origin: 0.5,
        transition: { duration: 1, loop: Infinity },
      }}
    >
      <AutorenewIcon data-testid="spinner" />
    </motion.div>
  </div>
);

export default Spinner;
